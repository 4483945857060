import type { BackendCurrencyModel } from '~/data/services/types/additional.type';

export interface AppCurrencyModel {
  id: number;
  shortName: string;
  name: string;
  sortOrder: number;
  status: number;
  logo: string | null;
}

function createAppCurrencyModel(raw: BackendCurrencyModel): AppCurrencyModel {
  return {
    id: raw.id,
    shortName: raw.short_name,
    name: raw.name,
    sortOrder: raw.sort_order,
    status: raw.status,
    logo: raw.logo,
  };
}

// function getCurrencyLabelById() {}

export { createAppCurrencyModel };
