import type { CustomFetch } from '~/shared/types/custom-fetch.type';
import type {
  BackendCurrencyModel,
  BlockchainsApiResponse,
  CategoriesApiResponse,
} from '~/data/services/types/additional.type';
import type { ItemsListRequestParams } from '~/shared/types/items-list.type';
import { itemsListRequestParamsMapper } from '~/utils/items-list-request-params-mapper';
import { createAppCurrencyModel } from '~/data/model/currency.model';
import type { AppCurrencyModel } from '~/data/model/currency.model';

export const AdditionalService = (fetch: CustomFetch) => ({
  async getBlockchains(payload: ItemsListRequestParams): Promise<BlockchainsApiResponse> {
    return await fetch('api/additional/blockchains', {
      params: itemsListRequestParamsMapper(payload),
    });
  },

  async getCategories(payload: ItemsListRequestParams): Promise<CategoriesApiResponse> {
    return await fetch('api/additional/categories', {
      params: itemsListRequestParamsMapper(payload),
    });
  },

  async getCurrencies(): Promise<AppCurrencyModel[]> {
    const response: BackendCurrencyModel[] = await fetch('api/additional/currency');

    return response.map(createAppCurrencyModel);
  },
});
